import Container from 'react-bootstrap/Container'
import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import ErrorView from './views/ErrorView'
import Unternehmen from './views/Unternehmen'
import Leitbild from './views/Leitbild'
import Kontakt from './views/Kontakt'
import Overview from './views/Overview'
import Detail from './views/Detail'
import UnderConstruction from './views/UnderConstruction'
import Verkauf from './views/Verkauf'
import Datenschutz from './views/Datenschutz'
import Impressum from './views/Impressum'
import AGB from './views/AGB'
import Unterstuetzung from './views/Unterstuetzung'
{
	/* import Dank from './views/Dank' */
}
{
	/* import Sponsoring from './views/Sponsoring'*/
}

function App() {
	let underConstruction: string[] = [
		'liechtenstein',
		'deutschland',
		'frankreich',
		'oesterreich',
		'england',
		'usa',
	]
	return (
		<Router>
			<div id="page-container">
				<div id="content-wrap">
					<Navigation />
					<Container>
						<Switch>
							<Route exact path="/" component={Overview} />
							<Route
								exact
								path="/unternehmen"
								component={Unternehmen}
							/>
							<Route
								exact
								path="/leitbild"
								component={Leitbild}
							/>
							<Route exact path="/kontakt" component={Kontakt} />
							<Route
								exact
								path="/overview/:group/:anzahl/:seite/:level?"
								component={Overview}
							/>
							<Route
								exact
								path="/detail/:katId/:znr/:group?"
								component={Detail}
							/>
							<Route
								exact
								path="/verkaufen"
								component={Verkauf}
							/>
							<Route
								exact
								path="/datenschutz"
								component={Datenschutz}
							/>
							<Route
								exact
								path="/impressum"
								component={Impressum}
							/>
							<Route exact path="/agb" component={AGB} />
							<Route
								exact
								path="/unterstuetzung"
								component={Unterstuetzung}
							/>
							{/*
							<Route exact path="/dank" component={Dank} />
*/}
							{/*
							<Route
								exact
								path="/sponsoring"
								component={Sponsoring}
							/>
*/}
							{underConstruction.map((p, i) => (
								<Route
									exact
									key={i}
									path={'/' + p}
									component={UnderConstruction}
								/>
							))}
							<Route component={ErrorView} />
						</Switch>
					</Container>
					<Footer />
				</div>
			</div>
		</Router>
	)
}

export default App
