import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Leitbild extends Component {
	render() {
		return (
			<>
				<Container>
					<code className="component_info">Leitbild.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>Leitbild</h1>
								<hr />
								<h2>Sorgfältig</h2>
								<p>
									behandeln wir jeden Auftrag und jedes
									Objekt.
								</p>

								<h2>Passioniert</h2>
								<p>
									verfolgen wir das aktuelle Geschehen der
									Branche.
								</p>

								<h2>Erfahren</h2>
								<p>
									stehen wir Ihnen bei Ihrem Projekt zur
									Seite.
								</p>

								<h2>Fair</h2>
								<p>
									und gleich werden alle Auktions-Teilnehmer
									behandelt, denn wir haben keine Einsicht auf
									die Gebotshöhen auf Philasearch.ch.
								</p>

								<hr />
								<h2>Von Sammlern für Sammler</h2>
								<p>
									Zur Zeit ist die R&J universal AG noch ein
									kleines Unternehmen, jedoch mit grossem
									Sammlergeist. Wir leben Briefmarken, kennen
									die Freuden, Wünsche und Sorgen von
									Philatelistinnen und Philatelisten und sind
									darum ein kompetenter und zugleich
									unkomplizierter Partner für Sie und Ihr
									Anliegen. Der direkte, stehts gut gepflegte
									Kontakt zu unseren Kunden sowie bestmögliche
									Transparenz ist uns wichtig – durch unsere
									überschaubare Grösse wird dies auch möglich.
									Wir sehen uns als Ergänzung zu grossen
									Auktionshäusern oder -plattformen –
									keineswegs als Konkurrenz.
								</p>

								<h2>Kompetenz durch Passion und Aktualität</h2>
								<p>
									Da unser Briefmarken-Angebot auch zugleich
									unsere grösste Leidenschaft darstellt, fällt
									es uns leicht, immer am Puls der Zeit zu
									bleiben. Unser philatelistisches Interesse,
									nicht nur an grossen Sammlungen und
									wertvollen Einzelstücken, sondern auch an
									weniger prestigeträchtigen Objekten, treibt
									uns an, Neuigkeiten aus der Branche im Auge
									zu behalten und uns mit anderen
									Wissensträgern auszutauschen.
								</p>

								<h2>
									Höchste Bildqualität durch beste
									Technologien
								</h2>
								<p>
									Um für Sie die Bewertung der Verkaufsobjekte
									am Bildschirm so präszise und einfach wie
									möglich zu machen, verwenden wir für die
									Erfassung hochwertige Geräte und stellen die
									Bilder in hoher Auflösung und korrektem
									Farbprofil zur Verfügung. Kleinste Details
									werden dadurch für Sie sichtbar und helfen
									Ihnen bei Ihrer Entscheidung. (Achtung: Um
									die korrekten Farben dargestellt zu
									bekommen, benötigen Sie einen kalibrierten
									Bildschirm.)
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

export default Leitbild // Don’t forget to use export default!
