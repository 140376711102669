import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Impressum extends Component {
	render() {
		return (
			<>
				<Container>
					<code className="component_info">Impressum.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>Impressum</h1>

								<p>
									<strong>R&amp;J universal AG</strong>
									<br />
									Bösch 41
									<br />
									6331 Hünenberg
									<br />
									Schweiz
								</p>
								<p>
									Telefon: +41 44 777 11 22
									<br />
									E-Mail:{' '}
									<a href="mailto:info@rj-stamp.ch">
										info@rj-stamp.ch
									</a>
								</p>
								<p>
									UID-Nummer: CHE-113.622.298 MWST
									<br />
									Handelsregister-Nummer: CH-130.3.012.337-2
								</p>
								<p>
									Firmensitz: Bösch 41, 6331 Hünenberg
									<br />
									Geschäftsleitung: Rolf Hauser
									<br />
									E-mail:{' '}
									<a href="mailto:rolf.hauser@rj-stamp.ch">
										rolf.hauser@rj-stamp.ch
									</a>
								</p>
								<p>
									<strong>Bankverbindung</strong>
									<br />
									Zürcher Kantonalbank (ZKB)
									<br />
									IBAN: CH84 0070 0110 0064 1894 7
									<br />
									Swift BIC: ZKBKCHZZ80A
								</p>
								<hr />
								<h2>Haftungsausschluss</h2>
								<p>
									Die auf der Website publizierten
									Informationen werden von der R&amp;J
									universal AG ausschliesslich zum
									persönlichen Gebrauch sowie zu
									Informationszwecken bereitgestellt. R&amp;J
									universal AG übernimmt keine Gewährleistung
									(weder ausdrücklich noch stillschweigend)
									für Richtigkeit, Vollständigkeit und
									Aktualität der auf dieser Website
									publizierten Informationen, selbst wenn die
									gebotene Sorgfalt bei deren Sammlung aus für
									vertrauenswürdig gehaltenen Quellen
									angewendet wurde.
								</p>
								<p>
									Haftungsansprüche gegen den Autor wegen
									Schäden materieller oder immaterieller Art,
									welche aus dem Zugriff oder der Nutzung bzw.
									Nichtnutzung der veröffentlichten
									Informationen, durch Missbrauch der
									Verbindung oder durch technische Störungen
									entstanden sind, werden ausgeschlossen.
								</p>
								<p>
									Alle Angebote sind unverbindlich. Die
									R&amp;J universal AG behält es sich
									ausdrücklich vor, Teile der Seiten oder das
									gesamte Angebot ohne gesonderte Ankündigung
									zu verändern, zu ergänzen, zu löschen oder
									die Veröffentlichung zeitweise oder
									endgültig einzustellen.
								</p>
								<p>
									Verweise und Links auf Webseiten Dritter
									liegen ausserhalb unseres
									Verantwortungsbereichs. Es wird jegliche
									Verantwortung für solche Webseiten
									abgelehnt. Der Zugriff und die Nutzung
									solcher Webseiten erfolgen auf eigene Gefahr
									des Nutzers oder der Nutzerin.
								</p>
								<hr />
								<h2>Urheberrecht</h2>
								<p>
									Die Urheber- und alle anderen Rechte an
									Inhalten, Bildern, Fotos oder anderen
									Dateien auf der Website gehören
									ausschliesslich der R&amp;J universal AG
									oder den speziell genannten Rechtsinhabern.
									Für die Reproduktion jeglicher Elemente ist
									die schriftliche Zustimmung der
									Urheberrechtsträger im Voraus einzuholen.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

export default Impressum // Don’t forget to use export default!
