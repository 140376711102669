import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Kontakt extends Component {
	render() {
		return (
			<>
				<Container>
					<code className="component_info">Kontakt.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>Kontakt</h1>
								<hr />
								<h2>
									Möchten Sie Ihre Sammlung verkaufen oder
									haben Sie sonst eine Frage?
								</h2>
								<p>Wir freuen uns über Ihre Kontaktaufnahme.</p>
								<hr />
								<Row>
									<Col xs={12} sm={6}>
										<p>
											<b>R&J universal AG</b>
											<br />
											Bösch 41
											<br />
											6331 Hünenberg
											<br />
											Schweiz
											<br />
											<br />
											Telefon: +41 44 777 11 22
											<br />
											E-Mail:{' '}
											<a href="mailto:info@rj-stamp.ch">
												info@rj-stamp.ch
											</a>
										</p>
									</Col>
									<Col xs={12} sm={6}>
										<p>
											<b>Öffnungszeiten</b>
											<br />
											Montag bis Freitag
											<br />
											von 8–12 Uhr und von 13–18 Uhr
										</p>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

export default Kontakt
