import CategoryCollection from '../models/CategoryCollection'
import Color from '../models/Color'
import Price from '../interfaces/Price'
import Product from '../models/Product'
import Variety from '../models/Variety'
import stylePrice from '../functions/stylePrice'
import { Language } from '../types/Language'
import { REQUESTURL } from '../constants/urls'

export default class Group {
	collection: Product[]
	count: number
	localStorageKey: string

	constructor() {
		this.collection = []
		this.count = 0
		this.localStorageKey = 'token'
	}

	getSelectionCount(): number {
		return this.collection.filter((p) => p.selected === true).length
	}

	getSelectedProducts(): Product[] {
		return this.collection.filter((product) => product.selected === true)
	}

	getPreviousProduct(product: Product | undefined): Product | undefined {
		if (product !== undefined) {
			let index: number = this.collection.indexOf(product)
			if (index > 0 && index <= this.collection.length - 1) {
				return this.collection[index - 1]
			}
		}
		return undefined
	}

	getLayoutType(): number {
		let product: Product | undefined = this.collection[0]
		if (product !== undefined) {
			return product.layout
		}
		return 0
	}

	getNextProduct(product: Product | undefined): Product | undefined {
		if (product !== undefined) {
			let index: number = this.collection.indexOf(product)
			if (index >= 0 && index < this.collection.length - 1) {
				return this.collection[index + 1]
			}
		}
		return undefined
	}

	getFirstProduct(): Product | undefined {
		return this.collection[0]
	}

	getLastProduct(): Product | undefined {
		return this.collection[this.collection.length - 1]
	}

	getSelectedProduct(): Product | undefined {
		return this.collection.find((product) => product.selected === true)
	}

	getValueCount(): string {
		let output: string = this.collection.length + ' Werte'
		return output
	}

	hasLetters(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasLetters()) {
				output = true
			}
		}
		return output
	}

	hasSeals(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasSeals()) {
				output = true
			}
		}
		return output
	}

	hasDetails(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasDetails()) {
				output = true
			}
		}
		return output
	}

	hasProbations(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasProbations()) {
				output = true
			}
		}
		return output
	}

	hasFdcs(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasFdcs()) {
				output = true
			}
		}
		return output
	}

	hasVarieties(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasVarieties()) {
				output = true
			}
		}
		return output
	}

	hasColors(): boolean {
		let output = false
		for (let p of this.collection) {
			if (p.hasColors()) {
				output = true
			}
		}
		return output
	}

	getIssueDate(): string {
		let issueDate = ''
		for (let i in this.collection) {
			if (this.collection[i].ausgabetag !== '') {
				issueDate = this.collection[i].ausgabetag
			}
		}
		return issueDate
	}

	getHauserZusatz(): string {
		let hauserZusatz = ''
		for (let i in this.collection) {
			if (this.collection[i].hauser_zusatz !== '') {
				hauserZusatz = this.collection[i].hauser_zusatz
			}
		}
		return hauserZusatz
	}

	getGroupPrice<K extends keyof Price>(key: K) {
		let output: string | null = this.collection[0].prices[key]
		return output !== null ? stylePrice(output) : stylePrice(null)
	}

	calculatePrice<K extends keyof Price>(key: K): string {
		let output = 0
		let isNull: boolean = false
		for (let i in this.collection) {
			if (
				this.collection[i].prices[key] === null ||
				Number(this.collection[i].prices[key]) === 0
			) {
				isNull = true
			}
			output += Number(this.collection[i].prices[key]) * 100
		}
		output = output / 100
		return !isNull ? stylePrice(String(output)) : stylePrice(null)
	}

	// Diese Funktion sollte irgendwann durch die Funktion oberhalb abgelöst werden
	calculatePriceSpecial<K extends keyof Price>(key: K): string {
		let output = 0
		let isNull: boolean = false
		if (this.collection[0] !== undefined) {
			if (this.collection[0].prices[key] === null) {
				isNull = true
			}
			output += Number(this.collection[0].prices[key]) * 100
		}
		output = output / 100
		return !isNull ? stylePrice(String(output)) : stylePrice(null)
	}

	defineGroupNr(): string {
		let znrs: string[] = []
		for (let i in this.collection) {
			znrs.push(this.collection[i].znr)
		}
		//znrs.sort();
		let minZNr: string = znrs[0]
		let maxZNr: string = znrs[znrs.length - 1]

		return minZNr + '–' + maxZNr
	}

	unselectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = false
		}
	}

	selectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = true
		}
	}

	selectToggle(id: number) {
		let product: Product | undefined = this.getProductById(id)
		if (product !== undefined) {
			product.selected = !product.selected
		}
	}

	getProductById(id: number): Product | undefined {
		return this.collection.find((p) => p.id === id)
	}

	getProductByZnr(znr: string): Product | undefined {
		return this.collection.find((p) => p.znr === znr)
	}

	getTitle(language: Language): string {
		if (this.collection[0] !== undefined) {
			let de: string = this.collection[0].name_de
			let fr: string = this.collection[0].name_fr
			if (!fr) fr = de
			switch (language) {
				case 'de':
					return de
				case 'fr':
					return fr
				default:
					return de
			}
		} else {
			return ''
		}
	}

	getDescription(language: Language): string {
		if (this.collection[0] !== undefined) {
			let de: string = this.collection[0].langbeschrieb_de
			let fr: string = this.collection[0].langbeschrieb_fr
			if (!fr) fr = de
			switch (language) {
				case 'de':
					return de
				case 'fr':
					return fr
				default:
					return de
			}
		} else {
			return ''
		}
	}

	getYear(): string {
		let output: string = ''
		let years: string[] = []
		for (let i in this.collection) {
			years.push(this.collection[i].jahr)
		}
		years.sort()
		let minYear: string = years[0]
		let maxYear: string = years[years.length - 1]

		if (minYear === maxYear) {
			output = minYear
		} else {
			output = minYear + '–' + maxYear
		}
		return output
	}

	getZnr(): string {
		let output: string = ''
		let znrs: string[] = []
		for (let i in this.collection) {
			znrs.push(this.collection[i].znr)
		}
		znrs.sort()
		let minZnr: string = znrs[0]
		let maxZnr: string = znrs[znrs.length - 1]

		if (minZnr === maxZnr) {
			output = minZnr
		} else {
			output = minZnr + '–' + maxZnr
		}
		return output
	}

	async readApi(Store: any, kat_id: number, znr: string) {
		const _this = this
		const url = `${REQUESTURL}group/${kat_id}/${znr}`

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			})
			const json = await response.json()
			await _this.translateApi(json.products)
			Store.notify()
		} catch (err) {
			console.error(err)
		}
	}

	true_or_false(input: any) {
		if (Number(input) === 0 || input === null) {
			return false
		}
		return true
	}

	stringCheck(input: any): string {
		let output: string = String(input)
		return output !== 'null' ? output : ''
	}

	async translateApi(
		json: any,
		level?: number,
		categories?: number[],
		categoryCollection?: CategoryCollection
	) {
		const outputCollection = []

		// Produkte abarbeiten
		for (let p of json) {
			if (p.pro_znr_abart === '' && p.pro_znr_farbvariante === '') {
				if (level === 3 && categories && categoryCollection) {
					const categoryChildNumbers: number[] = categoryCollection.getCategoryChildNumbers(
						categories[0]
					)
					if (
						categoryChildNumbers.includes(Number(p.pro_kategorie))
					) {
						let product: Product = new Product()
						product.translateApi(p)
						outputCollection.push(product)
					}
				} else if (level === 4 && categories) {
					if (Number(p.pro_kategorie) === categories[0]) {
						let product: Product = new Product()
						product.translateApi(p)
						outputCollection.push(product)
					}
				} else {
					let product: Product = new Product()
					product.translateApi(p)
					outputCollection.push(product)
				}
			}
		}
		this.collection = outputCollection

		// Abarten den Produkten zuweisen
		let allVarieties: any[] = json.filter(
			(v: any) => v.pro_znr_abart !== ''
		)
		for (let v of allVarieties) {
			let product: Product | undefined = this.searchZnr(v.pro_znr)
			if (product) {
				let variety: Variety = new Variety()
				variety.translateApi(v)
				product.varieties.add(variety)
			}
		}

		// Farbvarianten den Produkten zuweisen
		let allColors: any[] = json.filter(
			(f: any) => f.pro_znr_farbvariante !== ''
		)
		for (let f of allColors) {
			let product: Product | undefined = this.searchZnr(f.pro_znr)
			if (product) {
				let color: Color = new Color()
				color.translateApi(f)
				product.colors.add(color)
			}
		}
	}

	searchId(id: number): Product | undefined {
		return this.collection.find((p) => p.id === id)
	}

	searchZnr(znr: string): Product | undefined {
		return this.collection.find((p) => p.znr === znr)
	}

	all() {
		return this.collection
	}
}
