import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import React from 'react'
import Row from 'react-bootstrap/Row'
import { RouteComponentProps } from 'react-router'
import Store from '../observerstore/Store'
import Observer from '../observerstore/Observer'
import Sidebar from '../components/Sidebar'
import ProductDetail from '../components/ProductDetail'
import GroupDetail from '../components/GroupDetail'
import CategoryCollection from '../models/CategoryCollection'
import SidebarButton from '../components/SidebarButton'
import Group from '../models/Group'
const $ = Store.data

interface MatchParams {
	katId: string
	znr: string
	group: string | undefined
}
interface Props extends RouteComponentProps<MatchParams> {}
interface State {
	group: Group
	categoryCollection: CategoryCollection
	showSidebar: boolean
}

export default class Detail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			group: new Group(),
			categoryCollection: new CategoryCollection(),
			showSidebar: true,
		}
		this.scrollTop()
	}

	initialize() {
		this.setState({
			group: new Group(),
		})
		$.group.readApi(
			Store,
			Number(this.props.match.params.katId),
			this.props.match.params.znr
		)
	}

	componentDidMount() {
		Store.attach(this)
		this.initialize()
	}

	componentDidUpdate(prevProps: any) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.initialize()
		}
	}

	update() {
		this.setStateNStoreForce({
			group: $.group,
			categoryCollection: $.categoryCollection,
			showSidebar: $.showSidebar,
		})
	}

	scrollTop() {
		document.body.scrollTop = 0 // For Safari
		document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
	}

	render() {
		return (
			<>
				<Container>
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Row>
						<Col
							xs={12}
							sm={5}
							md={5}
							lg={4}
							xl={3}
							className={
								this.state.showSidebar ? '' : 'unsichtbar'
							}
						>
							<Sidebar activeCategory={Number($.url.katId)} />
						</Col>
						<Col
							sm={this.state.showSidebar ? 7 : 12}
							lg={this.state.showSidebar ? 8 : 12}
							xl={this.state.showSidebar ? 9 : 12}
						>
							<Row>
								<Col sm={7} md={7} lg={8} xl={9}>
									<SidebarButton />
								</Col>
								<Col
									xs={12}
									sm={5}
									md={5}
									lg={4}
									xl={3}
									className="text-right"
								></Col>
							</Row>
							{this.props.match.params.group === undefined && (
								<>
									<ProductDetail
										product={this.state.group.getProductByZnr(
											this.props.match.params.znr
										)}
										group={this.state.group}
									/>
								</>
							)}
							{this.props.match.params.group !== undefined && (
								<>
									<GroupDetail group={this.state.group} />
								</>
							)}
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}
