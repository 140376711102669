import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'

export default class Unterstuetzung extends Component {
	render() {
		return (
			<>
				<Container>
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Row>
						<Col sm={7}>
							<div className="main">
								<h1>Unterstützung</h1>
								<hr />
								<p>
									Wenn Ihnen dieser digitale
									Briefmarkenkatalog gefällt und Sie das
									Projekt unterstützen möchten, können Sie
									dies gerne mit einer Überweisung auf
									folgendes Konto tun.
								</p>
								<p>
									<p
										style={{
											marginBottom: '5px',
											fontWeight: 600,
										}}
									>
										CH84 0070 0110 0064 1894 7
									</p>
									<i>lautend auf</i>
									<br />
									R&J universal AG
									<br />
									Bösch 41
									<br />
									6331 Hünenberg
								</p>
							</div>
						</Col>
						<Col sm={5}>
							<div className="main sidebar-right">
								<h1>Helfer werden</h1>
								<hr />
								<p>
									Verfügen Sie über interessantes
									Briefmarkenmaterial, das diesen Katalog
									bereichern würde oder möchten Sie den
									Katalog mit Ihrem Fachwissen unterstützen,
									dann nehmen Sie doch bitte Kontakt mit uns
									auf. Am besten per E-Mail an{' '}
									<a href="mailto:rolf.hauser@rj-stamp.ch">
										rolf.hauser@rj-stamp.ch
									</a>
									.
									<p style={{ marginTop: '5px' }}>
										Wir freuen uns auf Ihre Kontaktaufnahme.
									</p>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}
